import { Link } from 'react-router-dom';
import ArrowIcon from '../../assets/ArrowIcon.svg';
import WhiteArrowIcon from '../../assets/WhiteArrowIcon.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { selectProduct } from '../../../../store/productsSlice';
import { selectApplication } from '../../../../store/applicationsSlice';

interface ExpandingButtonProps {
  title: string;
  bgColor: string;
  hoverBgColor: string;
  textColor: string;
  hoverTextColor: string;
  IconType: string;
  id: number;
}

function NonExpandingButton({
  title,
  bgColor,
  hoverBgColor,
  textColor,
  hoverTextColor,
  IconType,
  id,
}: ExpandingButtonProps) {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toValues: { [key: string]: string } = {
    [t('More Details')]: '/applications',
    [t('View Product')]: '/products',
  };

  const handleNavigate = () => {
    if (title === t('More Details')) {
      dispatch(selectApplication(id));
    } else if (title === t('View Product')) {
      dispatch(selectProduct(id));
    }
  };

  const toValue = toValues[title] || '/';

  return (
    <Link
      to={toValue}
      onClick={handleNavigate}
      className={`flex transition-width duration-300 hover:${hoverBgColor} hover:${hoverTextColor} items-center gap-2 ${bgColor} ${textColor} py-2 px-4 h-[2.7rem] w-[12rem] justify-center`}
    >
      <span
        className={`font-bold text-center ${
          currentLanguage === 'ar' ? 'mb-[0.5rem]' : ''
        }`}
      >
        {title}
      </span>
      <img
        src={IconType === 'White' ? WhiteArrowIcon : ArrowIcon}
        alt="Arrow Icon"
        className="w-fit h-fit"
        loading="lazy"
      />
    </Link>
  );
}

export default NonExpandingButton;
