import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import store from './store/store';
import i18n from './i18next/i18n';
import App from './App';
import 'aos/dist/aos.css';
import './index.css';


const root = document.getElementById('root');

if (root) {
  createRoot(root).render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  );
}
