import { useDispatch, useSelector } from 'react-redux';
import CalcModalButton from '../Main components/Modals/CalcModalButton';
import ChemaGroupIcon from './assets/ChemaGroup.webp';
import { Link } from 'react-router-dom';
import { RootState } from '../../store/store';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import DropdownIcon from './assets/Dropdown.svg';
import { useState } from 'react';
import EditProfileIcon from './assets/EditProfileIcon.svg';
import SignOutIcon from './assets/SignOut.svg';
import { setLoggedin } from '../../store/authSlice';
import { useNavigate } from 'react-router-dom';

function AwardsHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedin = useSelector((state: RootState) => state.auth.loggedin);
  const user = useSelector((state: RootState) => state.user.user);
  const [isDropdownVisible, setDropdownVisibility] = useState(false);

  const handleLogout = () => {
    dispatch(setLoggedin(false));
    setDropdownVisibility(false);
    navigate('/Awards');
  };
  const handleUpdate = () => {
    navigate('/UpdateProfile');
  };

  return (
    <div className="w-screen custom-height-mq:flex fixed z-30 hidden flex-row justify-center items-center h-[6.25rem] bg-[#1F3566] pl-[7.5rem] pr-[5rem]">
      <Link to="/">
        <img
          src={ChemaGroupIcon}
          alt="Chema Group"
          className="w-[9.98625rem] h-[3.25rem]"
        />
      </Link>
      <div className="flex-grow" />
      <div className="flex flex-row gap-8">
        {loggedin === false ? (
          <>
            <Link to="/login">
              <CalcModalButton
                title="تسجيل الدخول"
                bgColor="bg-[#D7D7D7]"
                TextColor="text-[#868686]"
                hoverBgColor="bg-[#1C4585]"
                hoverTextColor="text-white"
                Awards={true}
                isRegister={true}
              />
            </Link>
            <Link to="/register">
              <CalcModalButton
                title="التسجيل في البرنامج"
                bgColor="bg-[#FBAD4A]"
                TextColor="text-[#1C4585]"
                hoverBgColor="bg-[#1C4585]"
                hoverTextColor="text-white"
                Awards={true}
                isLogin={true}
              />
            </Link>
          </>
        ) : (
          <div
            onClick={() => setDropdownVisibility(!isDropdownVisible)}
            className="flex flex-row gap-3 relative hover:cursor-pointer rtl"
          >
            <Avatar
              size={40}
              style={{
                backgroundColor: '#fff',
                color: '#1F3566',
              }}
              className="flex items-center justify-center"
              icon={<UserOutlined />}
            />
            <div className="flex flex-row gap-1 justify-center items-center">
              <div className="font-[500] text-base text-white !arabicLanguage">
                {user?.national_id}
              </div>
              <img
                src={DropdownIcon}
                alt="DropMenu"
                className="w-[1.5rem] h-[1.5rem] mt-[0.2rem]"
              />
            </div>
          </div>
        )}
        {isDropdownVisible && (
          <div className="absolute mt-[2.7rem] ml-[-7rem] flex items-center justify-start ContextCardShadow p-[1rem] min-w-[10.9375rem] min-h-[6rem] bg-[#FBAD4A] rtl shadow-md">
            <ul className="text-[#1C4585] flex flex-col gap-2 justify-between h-[90%]">
              {['تعديل الملف الشخصي', 'تسجيل خروج'].map((item) => (
                <li
                  key={item}
                  className="flex flex-row gap-2 items-center font-medium hover:cursor-pointer"
                >
                  <img
                    src={item === 'تسجيل خروج' ? SignOutIcon : EditProfileIcon}
                    alt="Edit Profile"
                    className="w-[1.25rem] h-[1.25rem]"
                  />
                  <div
                    onClick={
                      item === 'تسجيل خروج' ? handleLogout : handleUpdate
                    }
                    className="text-sm hover:text-white !arabicLanguage mb-[0.3rem]"
                  >
                    {item}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default AwardsHeader;
