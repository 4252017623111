import { useEffect , useState } from "react";
import DreamsCard from "./DreamsCard";

interface BuildYourDreamsCardProbs {
  items : Array<any>;
}

function BuildYourDreamsCard({items} : BuildYourDreamsCardProbs) {
  const [activeCardIndex, setActiveCardIndex] = useState<number>(2);
  const [cardPressed , setCardPressed] = useState<boolean>(true);
  const [cardData, setCardData] = useState<CardData[]>([
    {
      index: 0,
      icon: items[0]["platform"].logo,
      image: items[0]['images'][0].image,
      uploadDate: items[0].post_date,
      postHeader: "Chema Foam - كيما فوم",
      context: items[0].content,
      post_url: items[0].post_url
    },
    {
      index: 1,
      icon: items[1]["platform"].logo,
      image: items[1]['images'][0].image,
      uploadDate: items[1].post_date,
      postHeader: "Chema Foam - كيما فوم",
      context: items[1].content,
      post_url: items[1].post_url
    },
    {
      index: 2,
      icon: items[2]["platform"].logo,
      image: items[2]['images'][0].image,
      uploadDate: items[2].post_date,
      postHeader: "Chema Foam - كيما فوم",
      context: items[2].content,
      post_url: items[2].post_url
    },
    {
      index: 3,
      icon: items[3]["platform"].logo,
      image: items[3]['images'][0].image,
      uploadDate: items[3].post_date,
      postHeader: "Chema Foam - كيما فوم",
      context: items[3].content,
      post_url: items[3].post_url
    },
    {
      index: 4,
      icon: items[4]["platform"].logo,
      image: items[4]['images'][0].image,
      uploadDate: items[4].post_date,
      postHeader: "Chema Foam - كيما فوم",
      context: items[4].content,
      post_url: items[4].post_url
    },
  ]);

  interface CardData {
    index: number;
    icon: string;
    image: string;
    uploadDate: string;
    postHeader: string;
    context: string;
    post_url : string;
  }

  const swapIndexes = (arr: CardData[], index1: number, index2: number): CardData[] => {
    let newArr = [...arr];
    const tempIndex = newArr[index1];
    newArr[index1] = newArr[index2];
    newArr[index2] = tempIndex;
    return newArr;
  };

  useEffect(() => {
    if (activeCardIndex !== 2)
    {
    setCardData(swapIndexes(cardData, 2, activeCardIndex));
    }
  }, [cardPressed]);



  
  return (
    <div className="flex flex-row gap-5 justify-center items-center w-[57.75rem] h-[26.293125rem] custom-height-mq:h-[48.4375rem]">
      <div className="flex flex-col gap-[5.5rem] relative left-[5rem]">
        <DreamsCard
          key={cardData[0].index}
          updated = { cardData[0].index === activeCardIndex }
          index={cardData[0].index}
          Active={false}
          icon={cardData[0].icon}
          image={cardData[0].image}
          uploadDate={cardData[0].uploadDate}
          postHeader={cardData[0].postHeader}
          context={cardData[0].context}
          post_url={cardData[0].post_url}
          onClick={() => {setActiveCardIndex(0); setCardPressed(!cardPressed);}}
        />
        <DreamsCard
          key={cardData[1].index}
          updated = { cardData[1].index === activeCardIndex }
          index={cardData[1].index}
          Active={false}
          icon={cardData[1].icon}
          image={cardData[1].image}
          uploadDate={cardData[1].uploadDate}
          postHeader={cardData[1].postHeader}
          context={cardData[1].context}
          post_url={cardData[1]['post_url']}
        onClick={() => {setActiveCardIndex(1); setCardPressed(!cardPressed);}}
        />
      </div>
      <div>
        <DreamsCard
          key={cardData[2].index}
          updated = { cardData[2].index === activeCardIndex }
          index={cardData[2].index}
          Active={true}
          icon={cardData[2].icon}
          image={cardData[2].image}
          uploadDate={cardData[2].uploadDate}
          postHeader={cardData[2].postHeader}
          context={cardData[2].context}
          post_url={cardData[2]['post_url']}
        onClick={() => {setActiveCardIndex(2); setCardPressed(!cardPressed);}}
        />
      </div>
      <div className="flex flex-col gap-[5.5rem] relative right-[5rem]">
        <DreamsCard
          key={cardData[3].index}
          updated = { cardData[3].index === activeCardIndex }
          index={cardData[3].index}
          Active={false}
          icon={cardData[3].icon}
          image={cardData[3].image}
          uploadDate={cardData[3].uploadDate}
          postHeader={cardData[3].postHeader}
          context={cardData[3].context}
          post_url={cardData[3]['post_url']}
        onClick={() => {setActiveCardIndex(3); setCardPressed(!cardPressed);}}
        />
        <DreamsCard
          key={cardData[4].index}
          updated = { cardData[4].index === activeCardIndex }
          index={cardData[4].index}
          Active={false}
          icon={cardData[4].icon}
          image={cardData[4].image}
          uploadDate={cardData[4].uploadDate}
          postHeader={cardData[4].postHeader}
          context={cardData[4].context}
          post_url={cardData[4]['post_url']}
        onClick={() => {setActiveCardIndex(4); setCardPressed(!cardPressed);}}
        />
      </div>
    </div>
  );
}

export default BuildYourDreamsCard;
