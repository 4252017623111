import { useState } from 'react';
import { ReactComponent as SemiBluePhoneCallIcon } from '../../Home/assets/SemiBluePhoneCall.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface CallUsIconProps {
  icon: string;
  ContactUs?: boolean;
  hotLine: string;
}

function CallUsIcon({ icon ,ContactUs  ,hotLine}: CallUsIconProps) {
  const currentLanguage = useSelector((state: RootState) => state.language.language);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`flex group flex-row transition-all gap-1 hover:scale-[1.1] items-center ${ContactUs ? "justify-start" :  "justify-center pt-[1.5rem]"} hover:cursor-pointer `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={` ${currentLanguage === "ar" ? "ltr" : ""} justify-center items-center flex flex-row gap-1`}>
      {isHovered ? (
        <SemiBluePhoneCallIcon className={`${ContactUs? "w-[1.5rem] h-[1.5rem] custom-height-mq:w-[2.5rem] custom-height-mq:h-[2.5rem]" : "w-[2.5rem] h-[2.5rem]"}`} />
      ) : (
        <img src={icon} className={`${ContactUs? "w-[1.5rem] h-[1.5rem] custom-height-mq:w-[2.5rem] custom-height-mq:h-[2.5rem]" : "w-[2.5rem] h-[2.5rem]"}`} alt="Icon" loading='lazy' />
      )}
      <div className={`font-bold text-left ${currentLanguage === "ar" ? "mb-[0.5rem]" : ""} ${ContactUs ? "text-[#868686]  text-3xl custom-height-mq:text-5xl" : "text-white text-5xl"}  group-hover:text-[#1EBAE5]`}>
        {hotLine}
      </div>
      </div>
    </div>
  );
}

export default CallUsIcon;
