import { Drawer } from 'antd';
import ChemaGroupIcon from '../../Home/assets/ChemaGroup.webp';
import ChemaFoamIcon from '../../Home/assets/ChemoFoam.svg';
import LeftSideNavLink from './LeftSideNavLink';
import HomeIcon from '../Header/assets/WhiteHomeIcon.svg';
import AboutIcon from '../Header/assets/AboutIcon.svg';
import AppIcon from '../Header/assets/AppIcon.svg';
import ProductIcon from '../Header/assets/ProductIcon.svg';
import SystemIcon from '../Header/assets/SystemIcon.svg';
import VideoIcon from '../Header/assets/VideoIcon.svg';
import DownloadIcon from '../Header/assets/DownloadIcon.svg';
import ContactIcon from '../Header/assets/ContactIcon.svg';
import EditProfileIcon from '../../Awards/assets/WhiteEditProfile.svg';
import SignOutIcon from '../../Awards/assets/SignoutWhite.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { setLoggedin } from '../../../store/authSlice';

interface MobileDrawerProps {
  visible: boolean;
  onClose: () => void;
  awards?: boolean;
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({
  visible,
  onClose,
  awards,
}) => {
  const loggedin = useSelector((state: RootState) => state.auth.loggedin);
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(setLoggedin(false));
    navigate('/Awards');
    onClose();
  };

  return !awards ? (
    <Drawer
      placement={currentLanguage === 'en' ? 'left' : 'right'}
      closable={false}
      onClose={onClose}
      open={visible}
      width={'70%'}
      styles={{
        mask: { backgroundColor: 'rgba(28, 69, 133, 0.8)' },
        content: {
          backgroundColor: '#1C4585',
          direction: currentLanguage === 'en' ? 'ltr' : 'rtl',
        },
      }}
    >
      <>
        <div className="flex flex-col gap-9">
          <img
            src={ChemaGroupIcon}
            alt="Chema Group"
            className="w-[6.515625rem] h-[2.125rem]"
          />
          <div className="flex flex-col gap-4">
            <LeftSideNavLink
              icon={HomeIcon}
              title={t('Home')}
              LinkTo={'/'}
              onClick={onClose}
            />
            <LeftSideNavLink
              icon={AboutIcon}
              title={t('About Chema Foam')}
              LinkTo={'/about'}
              onClick={onClose}
            />
            <LeftSideNavLink
              icon={AppIcon}
              title={t('Applications')}
              LinkTo={'/applications'}
              onClick={onClose}
            />
            <LeftSideNavLink
              icon={ProductIcon}
              title={t('Products')}
              LinkTo={'/products'}
              onClick={onClose}
            />
            <LeftSideNavLink
              icon={SystemIcon}
              title={t('Smart Solution System')}
              LinkTo={'/systems'}
              onClick={onClose}
            />
            <LeftSideNavLink
              icon={VideoIcon}
              title={t('Videos')}
              LinkTo={'/videos'}
              onClick={onClose}
            />
            <LeftSideNavLink
              icon={DownloadIcon}
              title={t('Downloads')}
              LinkTo={'/downloads'}
              onClick={onClose}
            />
            <LeftSideNavLink
              icon={ContactIcon}
              title={t('Contact Us')}
              LinkTo={'/contact'}
              onClick={onClose}
            />
          </div>
        </div>
        <img
          src={ChemaFoamIcon}
          alt="Chema Foam"
          className={`absolute bottom-7 ${
            currentLanguage === 'ar' ? 'left-7' : 'right-7'
          } w-[5.0625rem] h-[4.3888889rem]`}
        />
      </>
    </Drawer>
  ) : (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      open={visible}
      width={'70%'}
      styles={{
        mask: { backgroundColor: 'rgba(28, 69, 133, 0.8)' },
        content: {
          backgroundColor: '#1C4585',
          direction: 'rtl',
        },
      }}
    >
      <>
        <div className="flex flex-col gap-9">
          <img
            src={ChemaGroupIcon}
            alt="Chema Group"
            className="w-[6.515625rem] h-[2.125rem]"
          />
          <div className="flex flex-col gap-4">
            <LeftSideNavLink
              icon={EditProfileIcon}
              title={'تعديل الملف الشخصي'}
              LinkTo={''}
              onClick={onClose}
              awards={true}
            />
            <LeftSideNavLink
              icon={SignOutIcon}
              title={'تسجيل خروج'}
              LinkTo={'/Awards'}
              onClick={handleLogout}
              awards={true}
            />
          </div>
        </div>
        <img
          src={ChemaFoamIcon}
          alt="Chema Foam"
          className="absolute bottom-7 left-7 w-[5.0625rem] h-[4.3888889rem]"
        />
      </>
    </Drawer>
  );
};

export default React.memo(MobileDrawer);
