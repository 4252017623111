import { useSelector, useDispatch } from 'react-redux';
import ArrowIcon from '../assets/ArrowIcon.svg';
import WhiteArrowIcon from '../assets/WhiteArrowIcon.svg';
import { RootState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { selectSystem } from '../../../store/systemsSlice';
import { selectProduct } from '../../../store/productsSlice';

interface ExpandingButtonProps {
  title: string;
  bgColor: string;
  hoverBgColor: string;
  textColor: string;
  hoverTextColor: string;
  IconType: string;
  activeDotSection1?: number;
  System?: boolean;
  id?: number;
}

function ExpandingButton({
  title,
  bgColor,
  hoverBgColor,
  textColor,
  hoverTextColor,
  IconType,
  activeDotSection1,
  System,
  id,
}: ExpandingButtonProps) {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toValues: { [key: string]: string } = {
    ...(t('Who We Are') && !System ? { [t('Who We Are')]: '/about' } : {}),
    ...(t('Who We Are') && System ? { [t('Who We Are')]: '/systems' } : {}),
    'More Details': '/about',
    'View Product': '/products',
  };

  const handleNavigate = () => {
    if (id && title === t('View Product')) {
      dispatch(selectProduct(id));
    } else if (id && System) {
      dispatch(selectSystem(id));
    }
  };

  const toValue = toValues[title] || '/';

  return (
    <Link
      to={toValue}
      key={activeDotSection1}
      onClick={handleNavigate}
      className={`flex !transition-all duration-300 hover:${hoverBgColor} hover:${hoverTextColor} hover:custom-height-mq:w-[16rem] fade-in-active transform items-center gap-2 ${bgColor} ${textColor} py-2 px-4 h-[2.5rem] custom-height-mq:h-[3.7rem] w-[10.471875rem] custom-height-mq:w-[15rem] justify-center`}
    >
      <span
        className={`font-bold text-sm custom-height-mq:text-lg text-center ${
          currentLanguage === 'ar' ? 'mb-[0.5rem]' : ''
        }`}
      >
        {title}
      </span>
      <img
        src={IconType === 'White' ? WhiteArrowIcon : ArrowIcon}
        alt="Arrow Icon"
        className={`${
          currentLanguage === 'ar' ? 'rotate-180' : ''
        } w-[0.8375rem] h-[0.8375rem] custom-height-mq:w-[1.1rem] custom-height-mq:h-[1.1rem]`}
        loading="lazy"
      />
    </Link>
  );
}

export default ExpandingButton;
