import { useEffect, useState } from 'react';
import ChemaGroupIcon from '../../Home/assets/ChemaGroup.webp';
// import PyramidsIcon from "../../Home/assets/PyramidsIcon.svg";
import ArabicLanguageIcon from '../../Home/assets/ArabicLanguageIcon.svg';
import WhiteSearchIcon from '../../Home/assets/WhiteSearchIcon.svg';
import SearchBar from '../../Home/components/HelpSearchCard/SearchBar';
import NavHeader from './NavHeader';
import HeaderIcon from './HeaderIcons';
import MoreIcon from '../MobileBottomNavBar/assets/MoreWhiteIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../../i18next/langSlice';
import { RootState } from '../../../store/store';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import DropdownIcon from '../../Awards/assets/Dropdown.svg';
import MobileDrawer from './MobileDrawer';
import { Link } from 'react-router-dom';

interface HeaderProp {
  Active: string;
  awards?: boolean;
}

function Header({ Active, awards }: HeaderProp) {
  const [isFixed, setIsFixed] = useState(false);
  const [isSearchOn, setIsSearchOn] = useState(true);
  const dispatch = useDispatch();
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const loggedin = useSelector((state: RootState) => state.auth.loggedin);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const handleLanguageToggle = () => {
    const newLanguage = currentLanguage === 'ar' ? 'en' : 'ar';
    dispatch(setLanguage(newLanguage));
  };

  const handleSearch = () => {
    setIsSearchOn(!isSearchOn);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerScroll = 69;

      setIsFixed(scrollPosition >= triggerScroll);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-screen ${
        awards ? 'flex custom-height-mq:hidden' : 'flex'
      } top-[4.375rem] h-[3.75rem] custom-height-mq:h-[6.25rem] flex-row ${
        isFixed ? 'fixed !top-0' : 'absolute'
      } z-50 `}
    >
      <div className="w-[40%] custom-height-mq:w-[22.5%] h-full bg-opacity-75 bg-[#1F3566] flex items-center justify-end pr-[1rem] custom-height-mq:pr-[2rem]">
        <Link to="/">
          <img
            src={ChemaGroupIcon}
            alt="Chema group"
            className="w-[6.3125rem] h-[2.0625rem] custom-height-mq:w-[10.5625rem] custom-height-mq:h-[3.4375rem]"
          />
        </Link>
      </div>
      <div className="w-[2.5%] h-full" />
      <div className="w-[57.5%]  custom-height-mq:w-[75%] px-[1.5rem] custom-height-mq:px-[3rem] h-full bg-opacity-75 bg-[#1F3566] flex flex-row items-center justify-start custom-height-mq:justify-center">
        {isSearchOn ? (
          <div
            className={`flex-row gap-4 hidden custom-height-mq:flex ${
              currentLanguage === 'ar' ? 'rtl' : 'ltr'
            }`}
          >
            <NavHeader Active={Active === 'Home'} title="Home" />
            <NavHeader Active={Active === 'About Us'} title="About Us" />
            <NavHeader
              Active={Active === 'Applications'}
              title="Applications"
            />
            <NavHeader Active={Active === 'Products'} title="Products" />
            <NavHeader Active={Active === 'Systems'} title="Systems" />
            <NavHeader Active={Active === 'Videos'} title="Videos" />
            <NavHeader
              Active={Active === 'Downloads'}
              title="Downloads"
              Download={true}
            />
            <NavHeader Active={Active === 'Contact Us'} title="Contact Us" />
          </div>
        ) : (
          <SearchBar Header={true} />
        )}
        <div className="flex-grow hidden custom-height-mq:block" />
        <div
          className={`flex-row gap-1 items-center justify-center ${
            awards ? 'hidden' : 'flex'
          }`}
        >
          <HeaderIcon
            icon={WhiteSearchIcon}
            alt="Search"
            width="w-[1.0625rem]"
            height="h-[1.0625rem]"
            onClick={handleSearch}
          />
          <HeaderIcon
            icon={ArabicLanguageIcon}
            Language={true}
            alt="Arabic"
            width="w-[2.5rem]"
            height="h-[1.4375rem]"
            margin="ml-[0.5rem]"
            onClick={handleLanguageToggle}
          />
          {/* <HeaderIcon icon={PyramidsIcon} alt="Pyramids" width="w-[1.78rem]" height="h-[1.4rem]" /> */}
        </div>
        <div className="flex-grow block custom-height-mq:hidden" />
        {awards ? (
          loggedin ? (
            <div
              onClick={showDrawer}
              className="flex flex-row gap-2 relative hover:cursor-pointer rtl"
            >
              <Avatar
                size={30}
                style={{
                  backgroundColor: '#fff',
                  color: '#1F3566',
                }}
                className="flex items-center justify-center"
                icon={<UserOutlined />}
              />
              <div className="flex flex-row gap-1 justify-center items-center">
                <img
                  src={DropdownIcon}
                  alt="DropMenu"
                  className="w-[1.5rem] h-[1.5rem] mt-[0.2rem]"
                />
              </div>
            </div>
          ) : (
            <></>
          )
        ) : (
          <img
            src={MoreIcon}
            alt="More"
            className="w-[1.5rem] h-[1.5rem] block custom-height-mq:hidden"
            onClick={showDrawer}
          />
        )}
        <MobileDrawer
          visible={drawerVisible}
          onClose={closeDrawer}
          awards={awards}
        />
      </div>
    </div>
  );
}

export default Header;
