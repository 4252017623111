import { useEffect, useState } from 'react';
import BackgroundImage from './components/Background';
import TypographyHeader from './components/TypographyHeader';
import ContentTypography from './components/ContentTypography';
import ChemaIcon from './assets/ChemoFoam.svg';
import ExpandingButton from './components/ExpandingButton';
import UnderlineHeader from '../Main components/UnderlineHeader';
import IconButton from './components/IconButton';
import GreyPauseIcon from './assets/GreyPauseIcon.svg';
import LeftLinedArrowIcon from './assets/LeftLinedArrow.svg';
import RightLinedArrowIcon from './assets/RightLinedArrow.svg';
import ExpandingDots from './components/ExpandingDots';
import HelpSearchCard from './components/HelpSearchCard/HelpSearchCard';
import ContextCard from './components/ContextCard';
import AwardsIcon from './assets/Awards.svg';
import AppsIcon from './assets/Apps.svg';
import ArrowDownloadIcon from './assets/ArrowDownload.svg';
import ProductsIcon from './assets/Products.svg';
import VideosIcon from './assets/Video.svg';
import ContactUsIcon from './assets/Message.svg';
import AboutContext from './components/AboutContext';
import AboutContextContent from './components/AboutContextContent';
import ViewAllButton from './components/ViewAllButton';
import ProjectsCard from './components/ProjectsCard/ProjectsCard';
import ScrollableBox from './components/ProjectsCard/ScrollableBox';
import BuildYouDreamBG from './assets/BuildYourDreamBG.svg';
import BuildYourDreamHeader from './components/BuildYourDreamsCard/BuildYourDreamHeader';
import NavigateTopButton from '../Main components/NavigateTopButton';
import ContactUsButton from '../Main components/ContactUsButton';
import BuildYourDreamsCard from './components/BuildYourDreamsCard/BuildYourDreamsCard';
import FrequentlyAskedQuestionsCard from './components/FrequentlyAskedQuestionsCard/FrequentlyAskedQuestionsCard';
import MainFooter from '../Main components/MainFooter/MainFooter';
import Header from '../Main components/Header/Header';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import MobileBottomNavBar from '../Main components/MobileBottomNavBar/MobileBottomNavBar';
import HouseIcon from '../Main components/MobileBottomNavBar/assets/HomeIcon.svg';
import AppIcon from '../Main components/MobileBottomNavBar/assets/AppsIcon.svg';
import ProductIcon from '../Main components/MobileBottomNavBar/assets/ProductIcon.svg';
import ContactIcon from '../Main components/MobileBottomNavBar/assets/ContactIcon.svg';
import MoreIcon from '../Main components/MobileBottomNavBar/assets/MoreIcon.svg';
import Carousel from './components/Carousel';
import { useGetPageDataQuery } from '../../store/apiService';
import React from 'react';

function Home() {
  const [isPaused, setIsPaused] = useState(false);
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const { data, error, isLoading, refetch } = useGetPageDataQuery('Home', {
    skip: false, // Ensure that the query does not skip fetching data
  });

  useEffect(() => {
    refetch();
  }, [currentLanguage, refetch, error]);

  useEffect(() => {
    if (data && data['header'] && Array.isArray(data['header'])) {
      const paths: any = data['header'].map((item) => item.Background_image);
      setImagePaths(paths);
    }
  }, [data]);

  const { t } = useTranslation();
  const BottomNavBarIcons = [
    HouseIcon,
    AppIcon,
    ProductIcon,
    ContactIcon,
    MoreIcon,
  ];
  const BottomNavBarTitles = [
    t('Home'),
    t('Applications'),
    t('Products'),
    t('Contact Us'),
    t('More'),
  ];
  const activeDotsHome = [0, 1, 2, 3, 4];
  const [activeDotHomeSection, setActiveDotHomeSection] = useState(0);
  const [activeDotClientSection, setActiveDotClientSection] = useState(0);
  const [imagePaths, setImagePaths] = useState([]);
  const activeHomeData = data && data['header'][activeDotHomeSection];

  const getTitleBasedOnActiveDot = () => {
    return activeDotHomeSection % 2 === 0 ? t('More Details') : t('Who We Are');
  };

  const handleDotChange = (index: number, section: number) => {
    switch (section) {
      case 1:
        setActiveDotHomeSection(index);
        break;
      case 2:
        setActiveDotClientSection(index);
        break;
      default:
        break;
    }
  };

  return (
    data && (
      <div className="flex flex-col">
        <Header Active="Home" />
        <MobileBottomNavBar
          icons={BottomNavBarIcons}
          titles={BottomNavBarTitles}
          active={t('Home')}
        />
        <NavigateTopButton />
        <ContactUsButton />
        <div className="min-h-screen relative">
          <BackgroundImage
            images={imagePaths}
            setActiveDotSection1={setActiveDotHomeSection}
            activeDotSection1={activeDotHomeSection}
            isPaused={isPaused}
          />
          <div
            className={`text-justify absolute top-1/3 ${
              currentLanguage === 'ar'
                ? 'rtl right-8 custom-height-mq:right-28'
                : 'ltr left-8 custom-height-mq:left-28'
            }  flex flex-col gap-5 text-slate-50`}
          >
            <TypographyHeader
              activeDotSection1={activeDotHomeSection}
              text={activeHomeData.title}
            />
            <ContentTypography
              activeDotSection1={activeDotHomeSection}
              text={activeHomeData.description}
            />
            <ExpandingButton
              title={getTitleBasedOnActiveDot()}
              bgColor="bg-[#D7D7D7]"
              hoverBgColor="bg-[#FBAD4A]"
              textColor="text-[#1C4585]"
              hoverTextColor="text-[#1C4585]"
              IconType="Normal"
              activeDotSection1={activeDotHomeSection}
            />
            <div className="flex-grow" />
            <div className="flex-grow" />
            <div className="flex flex-col gap-4 w-fit items-center">
              <div className="flex gap-3">
                <IconButton
                  Icon={LeftLinedArrowIcon}
                  setActiveDotSection1={setActiveDotHomeSection}
                  activeDotSection1={activeDotHomeSection}
                  activeDots1={activeDotsHome}
                  Increase={false}
                  Header={true}
                />
                <IconButton
                  Icon={GreyPauseIcon}
                  Pause={true}
                  isPaused={isPaused}
                  setIsPaused={setIsPaused}
                />
                <IconButton
                  Icon={RightLinedArrowIcon}
                  setActiveDotSection1={setActiveDotHomeSection}
                  activeDotSection1={activeDotHomeSection}
                  activeDots1={activeDotsHome}
                  Increase={true}
                  Header={true}
                />
              </div>
              <ExpandingDots
                totalDots={data['header'].length}
                activeDot={activeDotHomeSection}
                onChange={(index) => handleDotChange(index, 1)}
              />
            </div>
          </div>
          <img
            src={ChemaIcon}
            alt="Chema Icon"
            className={`absolute ${
              currentLanguage === 'ar' ? 'left-5' : 'right-5'
            } bottom-10 w-[5.6875rem] h-[4.9375rem] custom-height-mq:bottom-8 custom-height-mq:h-[9.125rem] custom-height-mq:w-[10.5rem]`}
          />
        </div>
        <HelpSearchCard />
        <div
          className={`${
            currentLanguage === 'ar' ? 'rtl' : 'ltr'
          } flex flex-col mt-[3.5rem] mb-[-5rem] custom-height-mq:mb-[0rem] custom-height-mq:mt-[0rem] custom-height-mq:flex-row gap-3 custom-height-mq:gap-6 self-center`}
        >
          <div className="flex flex-row gap-6">
            <ContextCard
              title={t('AWARDS')}
              icon={AwardsIcon}
              LinkTo="Awards"
            />
            <ContextCard
              title={t('APPLICATIONS')}
              icon={AppsIcon}
              LinkTo="Applications"
            />
            <ContextCard
              title={t('PRODUCTS')}
              icon={ProductsIcon}
              LinkTo="Products"
            />
          </div>
          <div className="flex flex-row gap-6">
            <ContextCard
              title={t('VIDEOS')}
              icon={VideosIcon}
              LinkTo="Videos"
            />
            <ContextCard
              title={t('DOWNLOADS')}
              icon={ArrowDownloadIcon}
              LinkTo="Downloads"
            />
            <ContextCard
              title={t('CONTACT US')}
              icon={ContactUsIcon}
              LinkTo="Contact Us"
            />
          </div>
        </div>
        <div
          className={`w-screen ${
            currentLanguage === 'ar' ? 'rtl' : 'ltr'
          } p-[2rem] custom-height-mq:p-[5rem] gap-[2.5rem] custom-height-mq:gap-[7rem] min-h-[40.3125rem] p-[5rem 7.5rem 5rem 7.5rem] bg-[#1EBAE51A] bg-opacity-10 flex flex-col items-center justify-center`}
        >
          <UnderlineHeader title={t('About Chema Foam')} />
          <div className="flex flex-col justify-center items-center custom-height-mq:flex-row gap-6">
            <div className="flex items-center justify-center bg-opacity-20 w-[20.93125rem] custom-height-mq:w-[36rem] h-[11.904375rem] custom-height-mq:h-[20.375rem]">
              <iframe
                width="100%"
                height="100%"
                src={data['aboutUs'][0]['videos'][0]['video_url']}
                title="About Chema Foam"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="flex flex-col gap-2 h-[20.375rem] w-[36rem] justify-start items-center">
              <AboutContext text={data['aboutUs'][0]['title']} />
              <AboutContextContent text={data['aboutUs'][0]['description']} />
              <div className="flex-grow hidden custom-height-mq:block" />
              <div className="self-center mt-5 custom-height-mq:mt-0 custom-height-mq:self-start">
                <ExpandingButton
                  title={t('Who We Are')}
                  bgColor="bg-[#1C4585]"
                  hoverBgColor="bg-[#FBAD4A]"
                  textColor="text-[#FFFFFF]"
                  hoverTextColor="text-[#1C4585]"
                  IconType="White"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-screen p-[1rem] custom-height-mq:p-0 gap-[2rem] custom-height-mq:gap-[4rem] h-[35rem] custom-height-mq:h-[40.3125rem] p-[5rem 7.5rem 5rem 7.5rem] flex flex-col items-center justify-center">
          <UnderlineHeader title={t('APPlications')} />
          <ViewAllButton title={t('ALL APPLICATIONS')} />
          <Carousel items={data['applications']} type="applications" />
        </div>
        <div className="w-screen bg-[#FBAD4A] bg-opacity-10 p-[1rem] py-[1rem] custom-height-mq:py-[5rem] custom-height-mq:p-0 gap-[2rem] custom-height-mq:gap-[4rem] min-h-[35rem] custom-height-mq:min-h-[40.3125rem] p-[5rem 7.5rem 5rem 7.5rem] flex flex-col items-center justify-center">
          <UnderlineHeader title={t('Chema Foam Products')} />
          <ViewAllButton title={t('ALL PRODUCTS')} />
          <Carousel items={data['products']} type="products" />
        </div>
        <div className="w-screen p-[5rem] gap-[2rem] custom-height-mq:gap-[3rem] min-h-[40.3125rem] p-[5rem 7.5rem 5rem 7.5rem] flex flex-col items-center justify-center">
          <UnderlineHeader title={t('Smart Solution System')} />
          <ViewAllButton title={t('ALL SYSTEMS')} />
          <Carousel items={data['systems']} type="systems" />
        </div>
        <div className="w-screen bg-[#1EBAE5] bg-opacity-10 p-[5rem] gap-[5rem] min-h-[65.1875rem] p-[5rem 7.5rem 5rem 7.5rem] flex flex-col items-center justify-center">
          <div className="flex flex-col justify-center items-center gap-[4rem]">
            <UnderlineHeader title={data['projects'][0]['section_title']} />
            <ProjectsCard items={data['projects']} />
          </div>
          <div className="flex flex-col justify-center items-center gap-[4rem]">
            <UnderlineHeader title={t('Our Clients')} />
            <ScrollableBox
              activeDot={activeDotClientSection}
              clients={data['clients']}
            />
            <ExpandingDots
              totalDots={4}
              activeDot={activeDotClientSection}
              onChange={(index) => handleDotChange(index, 2)}
            />
          </div>
        </div>
        <div
          className="w-screen bg-cover custom-height-mq:gap-[5rem] h-[30.13625rem] custom-height-mq:h-[64rem] overflow-hidden flex flex-col justify-center items-center"
          style={{ backgroundImage: `url(${BuildYouDreamBG})` }}
        >
          <BuildYourDreamHeader title={t('#BuildYourDream')} />
          <BuildYourDreamsCard items={data['socialPosts']['data']} />
        </div>
        <div className="w-screen bg-cover gap-[1rem] custom-height-mq:gap-[5rem] p-[2rem] custom-height-mq:p-[5rem] min-h-[37.49625rem] custom-height-mq:min-h-[50rem] overflow-hidden flex flex-col justify-center items-center">
          <div className="py-[2rem] custom-height-mq:py-[3rem] self-center">
            <UnderlineHeader title={t('Frequently asked questions')} />
          </div>
          <FrequentlyAskedQuestionsCard Questions={data['questions']} />
        </div>
        <div>
          <MainFooter />
        </div>
      </div>
    )
  );
}

export default React.memo(Home);
