import WelcomeFrame from '../../Home/assets/MainHeader.webp';
import ChemaFoamIcon from '../../Home/assets/ChemoFoam.svg';
import WhiteRightArrow from '../../Home/assets/WhiteRightArrow.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';


interface MainHeaderProps{
    title : string;
    page: string;
}

function MainHeader ({title,page} : MainHeaderProps){
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    const { t } = useTranslation();
    return(
        <div className={`relative flex w-screen min-h-[20rem] custom-height-mq:min-h-[25rem] items-center justify-center ${currentLanguage === "ar" ? "rtl" : "ltr"}`}>
            <div className='z-10 flex flex-row w-[80vw] pt-[10rem]'>
                <div className='flex flex-col gap-8'>
                    <div className='text-xl custom-height-mq:text-5xl text-white font-bold custom-height-mq:leading-[4.875rem] tracking-normal text-left'>
                        {title}
                    </div>
                    <div className='flex flex-row justify-start items-center gap-3 custom-height-mq:gap-5'>
                        <Link to='/' className='text-[0.55rem] leading-[0.63rem] custom-height-mq:text-base font-medium custom-height-mq:leading-5 text-white tracking-normal text-left'>{t("Home")}</Link>
                        <img src={WhiteRightArrow} alt="Current Page" className={`w-[0.45rem] h-[0.45rem] custom-height-mq:w-[0.5rem] custom-height-mq:h-[1rem] ${currentLanguage === 'ar' ? "mt-[0.4rem]" : "mb-[0.05rem]"}`} /> 
                        <div className='text-[0.55rem] leading-[0.63rem] custom-height-mq:text-base font-medium custom-height-mq:leading-5 text-[#FBAD4A] tracking-normal text-left'>{page}</div>
                    </div>
                </div>
                <div className='flex-grow' />
                <img src={ChemaFoamIcon} alt='Chema Foam' className='w-[3.8125rem] h-[3.3125rem] custom-height-mq:w-[7.625rem] custom-height-mq:h-[6.625rem] self-end' />
            </div>
            <img src={WelcomeFrame} alt={page} className='object-cover w-screen h-full absolute inset-0 transition-opacity duration-500 fade-in-active' />
        </div>
    )
}

export default MainHeader;