import { useState } from 'react';
import GrayFilledArrow from '../assets/FilledGreyArrow.svg';
import { ReactComponent as BlueFilledArrow } from '../assets/FilledBlueArrow.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ViewAllProps {
  title: string;
  projects?: boolean;
}

function ViewAllButton({ title, projects }: ViewAllProps) {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const toValues: { [key: string]: string } = {
    [t('ALL APPLICATIONS')]: '/applications',
    [t('ALL PRODUCTS')]: '/products',
    [t('ALL SYSTEMS')]: '/systems',
  };

  const toValue = toValues[title] || '/';

  return (
    <Link
      to={toValue}
      className={`group absolute ${
        projects ? 'translate-y-[-12rem]' : 'translate-y-[-15rem]'
      } hidden custom-height-mq:flex right-[10rem]  flex-row gap-1 items-center justify-center hover:cursor-pointer`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`font-roboto text-base font-semibold leading-23 text-center ${
          isHovered ? 'text-[#1EBAE5]' : 'text-[#868686]'
        }`}
      >
        {title}
      </div>
      {isHovered ? (
        <BlueFilledArrow className="w-[1.4175rem] h-[0.70875rem]" />
      ) : (
        <img
          src={GrayFilledArrow}
          alt="View All"
          className="w-[1.4175rem] h-[0.70875rem]"
          loading="lazy"
        />
      )}
    </Link>
  );
}

export default React.memo(ViewAllButton);
