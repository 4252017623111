import { useState } from 'react';
import WhiteMessageIcon from '../Home/assets/WhiteMessageButton.svg';
import { ReactComponent as BlueMessageIcon } from '../Home/assets/BlueMessageButton.svg';
import { Link } from 'react-router-dom';

interface ContactUsButtonProps {
  Awards?: boolean;
}


function ContactUsButton({Awards} : ContactUsButtonProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      to="/Contact"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`fixed focus:outline-none top-1/2 ${Awards ? "left-[-1px]" : "right-[-1px]"} w-[2.5rem] h-[2rem]  custom-height-mq:w-[5rem] custom-height-mq:h-[4rem] hover:bg-[#FBAD4A] bg-opacity-90 z-50 p-[8px 16px] gap-8 bg-[#1C4585CC] flex items-center justify-center`}
    >
      {isHovered ? (
        <BlueMessageIcon className="w-[2.5rem] h-[2rem]" />
      ) : (
        <img src={WhiteMessageIcon} alt='Contact Us' className='w-[50%] h-[50%]' />
      )}
    </Link>
  );
}

export default ContactUsButton;
