import React from "react";
import FilledArrowIcon from "../assets/filledArrow.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface IconButtonProps {
    Icon: string; 
    setActiveDotSection1?: React.Dispatch<React.SetStateAction<number>>;
    activeDots1 ?: number[];
    activeDotSection1 ?: number;
    Increase ?: boolean;
    Pause?: boolean;
    isPaused?: boolean;
    setIsPaused?: React.Dispatch<React.SetStateAction<boolean>>;
    Header ?: boolean;
    onClick ?: () => void;
  }

  
  function IconButton({ Icon , setActiveDotSection1 , onClick ,  activeDotSection1 , activeDots1 , Increase , Pause , isPaused , setIsPaused ,Header }: IconButtonProps) {
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    
    const handleOnClick = () => {
      if (setActiveDotSection1 && activeDots1 && activeDotSection1 !== undefined) {
        const currentIndex = activeDots1.indexOf(activeDotSection1);
        if (currentIndex !== -1  && Increase) {
          const nextIndex = (currentIndex + 1) % activeDots1.length;
          setActiveDotSection1(nextIndex);
        }
        if (currentIndex !== -1 && !Increase) {
          const prevIndex = (currentIndex - 1 + activeDots1.length) % activeDots1.length;
          setActiveDotSection1(prevIndex);
        }
      }
      if(Pause && setIsPaused && (isPaused || !isPaused)){
        setIsPaused(!isPaused);
      }
    };

    return (
      <button className={`flex justify-center items-center hover:bg-[#FBAD4A]  ${isPaused ? "bg-[#FBAD4A]" : "bg-[#D7D7D7]"} w-[2rem] h-[2rem] custom-height-mq:w-[2.9375rem] custom-height-mq:h-[2.75rem]`} onClick={onClick ? onClick : handleOnClick}>
        <img src={isPaused ? FilledArrowIcon : Icon} alt="Icon" className={`w-[0.565625rem] h-[0.9375rem] custom-height-mq:w-[0.9375rem] custom-height-mq:h-[1.25rem] ${currentLanguage === "ar" && Header ? "rotate-180" : ""}`} loading="lazy" />
      </button>
    );
  }
  
  export default React.memo(IconButton);