interface AboutContextContentProps {
    text: string;
  }
  
  function AboutContextContent({ text }: AboutContextContentProps) {
    return (
      <div className="font-roboto text-[0.65rem] w-[19.125rem] custom-height-mq:w-full custom-height-mq:text-sm font-normal custom-height-mq:leading-6 text-center custom-height-mq:text-justify text-[#868686]">
        {text}
      </div>
    );
  }
  
  export default AboutContextContent;