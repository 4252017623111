import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';
import { RootState } from './store/store';
import Home from './Pages/Home/Home';
import About from './Pages/About Us/About';
import Contact from './Pages/Contact Us/contact';
import Applications from './Pages/Applications/Applications';
import Downloads from './Pages/Downloads/Downloads';
import Products from './Pages/Products/Products';
import Systems from './Pages/Systems/Systems';
import Videos from './Pages/Videos/Videos';
import TermsAndConditions from './Pages/Awards/Terms&Conditions/Terms&Conditions';
import Prizes from './Pages/Awards/Prizes/Prizes';
import Login from './Pages/Awards/LoginToTheProgram/LoginToTheProgram';
import Register from './Pages/Awards/RegisterInTheProgram/RegisterInTheProgram';
import CopounGiftCard from './Pages/Awards/CopounGiftCard/CopounGiftCard';
import RevisePoints from './Pages/Awards/RevisePoints/RevisePoints';
import ScrollToTop from './ScrollToTop';
import UpdateProfile from './Pages/Awards/UpdateProfile/UpdateProfile';

function App() {
  const { i18n } = useTranslation();
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
    document.documentElement.lang = i18n.language;
  }, [currentLanguage, i18n]);

  return (
    <Router>
      <SlideRoutes duration={800}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/applications" element={<Applications />} />
        <Route path="/products" element={<Products />} />
        <Route path="/systems" element={<Systems />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/awards" element={<TermsAndConditions />} />
        <Route path="/prizes" element={<Prizes />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/copoun" element={<CopounGiftCard />} />
        <Route path="/points" element={<RevisePoints />} />
        <Route path="/updateProfile" element={<UpdateProfile />} />
      </SlideRoutes>
      <ScrollToTop />
    </Router>
  );
}

export default App;
