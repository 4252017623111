import { useTranslation } from "react-i18next";
import CallUsIcon from "../../../Main components/MainFooter/CallUsIcon";
import CallUsGreyIcon from "../../assets/CallUsGreyIcon.svg";

interface CallUsContentProbs {
    hotLine : string;
}

function CallUsContent({hotLine} : CallUsContentProbs) {
    const { t } = useTranslation();
    return(
        <div className="flex flex-col px-[1.5rem] custom-height-mq:px-[2.5rem] gap-5">
            <div className="font-bold text-sm custom-height-mq:text-base text-[#5A5A5A]">
              {t("HOTLINE")}
            </div>
            <CallUsIcon icon={CallUsGreyIcon} hotLine={hotLine} ContactUs={true} />
        </div>
    )
}

export default CallUsContent;