import { Link } from 'react-router-dom';

interface CalcModalButtonProps {
  bgColor: string;
  hoverBgColor: string;
  TextColor: string;
  hoverTextColor: string;
  title: string;
  ContactUs?: boolean;
  Awards?: boolean;
  Login?: boolean;
  isRegister?: boolean;
  isLogin?: boolean;
  onClick?: () => void;
}

function CalcModalButton({
  bgColor,
  hoverBgColor,
  TextColor,
  hoverTextColor,
  title,
  ContactUs,
  Awards,
  Login,
  isRegister,
  isLogin,
  onClick,
}: CalcModalButtonProps) {
  const toValues: { [key: string]: string } = {
    ...(isLogin && { 'التسجيل في البرنامج': '/register' }),
    ...(isRegister && { 'تسجيل الدخول': '/login' }),
    ...(isLogin && { 'تسجيل الدخول': '/awards' }),
    'الرجوع بدون حفظ': '/awards',
    إلغاء: '/awards',
  };

  const toValue = toValues[title];

  return (
    <Link
      to={toValue}
      onClick={onClick}
      className={`  ${
        ContactUs
          ? 'w-[7.125rem] custom-height-mq:w-[12.25rem]'
          : Awards
          ? Login
            ? 'py-[0.75rem] px-[1rem] custom-height-mq:px-[2.5rem] !arabicLanguage'
            : 'w-[10.625rem] !text-sm pb-[0.3rem] !arabicLanguage'
          : 'w-[4.625rem] custom-height-mq:w-[9.0625rem]'
      } transition-all flex items-center justify-center hover:cursor-pointer ${bgColor} hover:${hoverBgColor} ${TextColor} hover:${hoverTextColor} h-[2.25rem] custom-height-mq:h-[3.75rem] p-[0.75rem 2.5rem 0.75rem 2.5rem] text-center text-xs custom-height-mq:text-lg font-bold leading-2`}
    >
      {title}
    </Link>
  );
}

export default CalcModalButton;
